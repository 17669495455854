var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "tile",
      class: { active: _vm.isActive },
      on: { click: _vm.onClick },
    },
    [
      _c(
        "list-tile",
        { staticClass: "list-tile-component", attrs: { height: "50px" } },
        [
          _c(
            "div",
            {
              staticClass: "align-center leading",
              attrs: { slot: "leading" },
              slot: "leading",
            },
            [
              _vm.isDeleteEditing
                ? _c(
                    "div",
                    { staticClass: "delete" },
                    [
                      _vm.checked
                        ? _c("checkbox-marked-outline-icon", {
                            staticClass: "center pointer",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.onCheck.apply(null, arguments)
                              },
                            },
                          })
                        : _c("checkbox-blank-outline-icon", {
                            staticClass: "center pointer",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.onCheck.apply(null, arguments)
                              },
                            },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "align-center overflow-hidden",
              attrs: { slot: "content" },
              slot: "content",
            },
            [
              _c(
                "p",
                {
                  staticClass: "label ellipsis",
                  style: { paddingRight: !_vm.isListEditing ? "8px" : "0px" },
                },
                [_vm._v(_vm._s(_vm.itemName))]
              ),
            ]
          ),
          _vm.isListEditing
            ? _c(
                "div",
                {
                  staticClass: "align-center trailing",
                  attrs: { slot: "trailing" },
                  slot: "trailing",
                },
                [
                  _c("img", {
                    staticClass: "drag-icon",
                    class: `${_vm.handleClassName}`,
                    attrs: { src: require("@/assets/img/icon/drag.png") },
                  }),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }