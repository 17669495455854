
import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import ListTemplate from "@/components/templates/ListTemplate.vue";
import ListHeader from "@/components/molecules/lists/ListHeader.vue";
import TemplateListItem from "@/components/molecules/lists/TemplateListItem.vue";
import TemplateDetail from "@/components/organisms/materials/TemplateDetail.vue";
import SortableList from "@/components/molecules/SortableList.vue";
import { ListState, MaterialInputTemplate } from "@/lib/models";
import PlaylistEditIcon from "icons/PlaylistEdit.vue";
import DeleteIcon from "icons/Delete.vue";
import { Dialog, isBilling } from "@/lib/utils";
import SimpleDialog, { SimpleDialogProps } from "@/components/ui/SimpleDialog.vue";

interface Data {
  currentListState: ListState;
  reorderableTemplates: MaterialInputTemplate[];
  selectedTemplates: MaterialInputTemplate[];
}

interface Methods {
  initialize: () => void;
  load: () => void;
  openInitialTemplate: () => void;
  addDeleteTemplate: (item: MaterialInputTemplate) => void;
  updateListState: (value: ListState) => void;
  updateMaterialInputTemplates: () => void;
  deleteMaterialInputTemplates: () => void;
  checked: (item: MaterialInputTemplate) => boolean;
  onCreateButtonClick: (event?: PointerEvent) => void;
  onUpdateButtonClick: (event: PointerEvent) => void;
  onDeleteButtonClick: (event: PointerEvent) => void;
}

interface Computed {
  isListEditing: boolean;
  isDeleteEditing: boolean;
  showUpdateButton: boolean;
  showDeleteButton: boolean;
  saveText: string;
  deleteCount: number;
  templates: MaterialInputTemplate[];
  recentTemplateId: string;
  sortOrder: string[] | [];
}

export default Vue.extend<Data, Methods, Computed, unknown>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: {
    ListTemplate,
    ListHeader,
    TemplateListItem,
    SortableList,
    TemplateDetail,
    PlaylistEditIcon,
    DeleteIcon,
  },

  data() {
    return {
      currentListState: ListState.COMPLETE,
      reorderableTemplates: [],
      selectedTemplates: [],
    };
  },

  created() {
    const { initialize } = this;
    initialize();
  },

  methods: {
    // init

    initialize() {
      const { load, openInitialTemplate } = this;
      this.currentListState = ListState.COMPLETE;
      this.selectedTemplates = [];
      load();
      openInitialTemplate();
    },
    load() {
      const { templates } = this;
      this.reorderableTemplates = templates;
    },
    async openInitialTemplate() {
      const { $router, $route, reorderableTemplates, recentTemplateId, onCreateButtonClick, $store, sortOrder } = this;
      const { dispatch } = $store;

      // 資料テンプレートの並び順をdisplayOrderの順に並び替える
      await dispatch("materialModule/fetchTemplateSortOrder");
      const sortedTemplates = reorderableTemplates.slice().sort((a, b) => {
        const indexA = sortOrder.indexOf(a.id);
        const indexB = sortOrder.indexOf(b.id);
        return indexA - indexB;
      });

      if ($route.params.templateId === recentTemplateId) return;

      if (sortedTemplates.length === 0) onCreateButtonClick();

      let templateId;
      if (sortedTemplates.some((x) => x.id === recentTemplateId)) templateId = recentTemplateId;
      else templateId = sortedTemplates[0].id;

      const params = { ...$route.params, templateId };
      if ($route.name) {
        $router.push({ name: $route.name, params });
      }
    },

    // update value (or execute action)

    addDeleteTemplate(template) {
      const { selectedTemplates } = this;
      if (selectedTemplates.includes(template)) {
        this.selectedTemplates = selectedTemplates.filter((selectedTemplate) => selectedTemplate.id !== template.id);
        return;
      }

      selectedTemplates.push(template);
    },
    updateListState(value) {
      this.currentListState = value;

      if (value === ListState.COMPLETE) {
        const { templates } = this;
        this.reorderableTemplates = templates;
        this.selectedTemplates = [];
      }
    },
    async updateMaterialInputTemplates() {
      const { reorderableTemplates, $store, initialize } = this;
      const { dispatch } = $store;

      const order = reorderableTemplates.map((template) => template.id);

      await dispatch("materialModule/updateTemplateSortOrder", order);
      initialize();
    },
    async deleteMaterialInputTemplates() {
      const { initialize, selectedTemplates } = this;
      const { dispatch } = this.$store;

      const positiveCallback = async () => {
        const deleteIds = selectedTemplates.map((template) => template.id);
        await dispatch("materialModule/deleteMaterialInputTemplate", deleteIds);
        initialize();
      };

      const confirmDialog = new Dialog(SimpleDialog);
      const data: SimpleDialogProps = {
        title: "資料テンプレートを削除しますか？",
        content: "本当に資料テンプレートを削除しますか？\n※元に戻すことはできません。",
        positive: "削除する",
        positiveCallback,
      };
      confirmDialog.show(data);
    },

    // return value to view

    checked(item) {
      const { selectedTemplates } = this;
      return selectedTemplates.includes(item);
    },

    // event handle (button, checkbox, etc)

    async onCreateButtonClick(_?) {
      const { $store, $route, $router } = this;
      const pushAnnounce = !(await isBilling($store));
      if (pushAnnounce) {
        $router.push({ name: "subscriptionAnnounce", query: { from: "createMaterialInputTemplate" } });
        return;
      }

      const { params } = $route;
      $router.push({
        name: "templateEdit",
        params: {
          ...params,
          templateId: uuidv4(),
        },
      });
    },
    onUpdateButtonClick(_) {
      const { updateMaterialInputTemplates } = this;
      updateMaterialInputTemplates();
    },
    onDeleteButtonClick(_) {
      const { selectedTemplates } = this;

      if (selectedTemplates.length < 1) {
        return;
      }

      const { deleteMaterialInputTemplates } = this;
      deleteMaterialInputTemplates();
    },
  },
  computed: {
    // check value

    isListEditing() {
      const { currentListState } = this;
      return currentListState === ListState.EDIT;
    },
    isDeleteEditing() {
      const { currentListState } = this;
      return currentListState === ListState.DELETE;
    },
    showUpdateButton() {
      const { isListEditing } = this;
      return isListEditing;
    },
    showDeleteButton() {
      const { isDeleteEditing, selectedTemplates } = this;
      return isDeleteEditing && selectedTemplates.length > 0;
    },

    // data

    saveText() {
      return "並び替えを保存する";
    },
    deleteCount() {
      const { selectedTemplates } = this;
      return selectedTemplates.length;
    },
    templates() {
      const { getters } = this.$store;
      return getters["materialModule/templates"];
    },
    recentTemplateId() {
      const { getters } = this.$store;
      return getters["materialModule/recentTemplateId"];
    },
    sortOrder() {
      const { getters } = this.$store;
      const templateSortOrder = getters["materialModule/templateSortOrder"];
      const order = templateSortOrder ? templateSortOrder.order : null;
      return order || [];
    },
  },
});
