var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("list-template", { attrs: { active: true } }, [
    _c(
      "div",
      { staticClass: "list-container", attrs: { slot: "side" }, slot: "side" },
      [
        _c("list-header", {
          staticClass: "list-header",
          attrs: {
            title: "資料テンプレート",
            showFolderIcon: false,
            isEditing: _vm.isListEditing || _vm.isDeleteEditing,
            updateListState: _vm.updateListState,
          },
        }),
        _c(
          "div",
          { staticClass: "list-items" },
          [
            _c("sortable-list", {
              attrs: {
                items: _vm.reorderableTemplates,
                keyAttr: "id",
                handleClassName: ".drag-icon",
              },
              on: {
                "update:items": function ($event) {
                  _vm.reorderableTemplates = $event
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ item }) {
                    return [
                      _c("template-list-item", {
                        attrs: {
                          isDeleteEditing: _vm.isDeleteEditing,
                          isListEditing: _vm.isListEditing,
                          template: item,
                          checked: _vm.checked(item),
                          addDeleteTemplate: _vm.addDeleteTemplate,
                          refresh: _vm.load,
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        !_vm.isListEditing && !_vm.isDeleteEditing
          ? _c(
              "div",
              {
                staticClass: "list-bottom label",
                on: { click: _vm.onCreateButtonClick },
              },
              [
                _c("img", {
                  staticClass: "white",
                  attrs: {
                    src: require("@/assets/img/menu-icon/template.png"),
                  },
                }),
                _vm._v("新規作成する "),
              ]
            )
          : _vm._e(),
        _vm.showUpdateButton
          ? _c(
              "div",
              {
                staticClass: "list-bottom label",
                on: { click: _vm.onUpdateButtonClick },
              },
              [
                _c("playlist-edit-icon", {
                  staticClass: "center save",
                  attrs: { size: 28, fillColor: "#ffffff" },
                }),
                _vm._v(_vm._s(_vm.saveText) + " "),
              ],
              1
            )
          : _vm._e(),
        _vm.showDeleteButton
          ? _c(
              "div",
              {
                staticClass: "list-bottom label delete",
                on: { click: _vm.onDeleteButtonClick },
              },
              [
                _c("delete-icon", {
                  staticClass: "center",
                  attrs: { fillColor: "#ffffff" },
                }),
                _vm._v("削除する（" + _vm._s(_vm.deleteCount) + "） "),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "template-detail", attrs: { slot: "main" }, slot: "main" },
      [_c("template-detail")],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }