var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.templateId && _vm.template
    ? _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.layoutType === "resume-layout",
                  expression: "layoutType === 'resume-layout'",
                },
              ],
              staticClass: "material-basic-items resume-layout",
            },
            [_vm._m(0), _vm._m(1)]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.layoutType === "enlargement-layout",
                  expression: "layoutType === 'enlargement-layout'",
                },
              ],
              staticClass: "material-basic-items enlargement-layout",
            },
            [_vm._m(2), _vm._m(3)]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.layoutType === "dictionary-layout",
                  expression: "layoutType === 'dictionary-layout'",
                },
              ],
              staticClass: "material-basic-items dictionary-layout",
            },
            [_vm._m(4)]
          ),
          _c("div", { staticClass: "divider" }),
          _c(
            "div",
            { staticClass: "material-items" },
            _vm._l(_vm.items, function (item) {
              return _c("div", { key: _vm.getKey(item) }, [
                item.type === "multiline-text"
                  ? _c("div", { staticClass: "material-item" }, [
                      _c("span", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.getTitle(item.title))),
                      ]),
                      _c("div", { staticClass: "space" }),
                      _c("span", { staticClass: "detail" }, [
                        _vm._v(_vm._s(_vm.detail) + " "),
                      ]),
                    ])
                  : _vm._e(),
                item.type === "image-square"
                  ? _c("div", { staticClass: "material-item" }, [
                      _c("span", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.getTitle(item.title))),
                      ]),
                      _vm._m(5, true),
                      _c("span", { staticClass: "detail" }, [
                        _vm._v(_vm._s(_vm.detail) + " "),
                      ]),
                    ])
                  : _vm._e(),
                item.type === "image-portrait"
                  ? _c("div", { staticClass: "material-item" }, [
                      _c("span", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.getTitle(item.title))),
                      ]),
                      _vm._m(6, true),
                      _c("span", { staticClass: "detail" }, [
                        _vm._v(_vm._s(_vm.detail) + " "),
                      ]),
                    ])
                  : _vm._e(),
                item.type === "image-landscape"
                  ? _c("div", { staticClass: "material-item" }, [
                      _c("span", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.getTitle(item.title))),
                      ]),
                      _vm._m(7, true),
                      _c("span", { staticClass: "detail" }, [
                        _vm._v(_vm._s(_vm.detail) + " "),
                      ]),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          ),
          _c("div", { staticClass: "space" }),
          _c(
            "button-round",
            {
              attrs: {
                to: { name: "templateEdit", params: { ..._vm.$route.params } },
              },
            },
            [_vm._v("編集する")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "basic-image" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/icon/image_default.png") },
      }),
      _c("span", [_vm._v("ここに画像が入ります")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "basic-text" }, [
      _c("span", { staticClass: "name" }, [_vm._v("名称が入ります")]),
      _c("span", { staticClass: "ruby" }, [_vm._v("ふりがなが入ります")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "basic-image" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/icon/image_default.png") },
      }),
      _c("span", [_vm._v("ここに画像が入ります")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "basic-text" }, [
      _c("span", { staticClass: "name" }, [_vm._v("名称が入ります")]),
      _c("span", { staticClass: "ruby" }, [_vm._v("ふりがなが入ります")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "basic-text" }, [
      _c("span", { staticClass: "name" }, [_vm._v("名称が入ります")]),
      _c("span", { staticClass: "ruby" }, [_vm._v("ふりがなが入ります")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "img-default img-square" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/icon/image_default.png") },
      }),
      _c("span", [_vm._v("ここに画像が入ります")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "img-default img-portrait" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/icon/image_default.png") },
      }),
      _c("span", [_vm._v("ここに画像が入ります")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "img-default img-landscape" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/icon/image_default.png") },
      }),
      _c("span", [_vm._v("ここに画像が入ります")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }